import React from 'react';

import { FLOWS } from "../misc/constants";
import HTTP from "../misc/HTTP";
import MainModal from './MainModal/MainModal';
import ParticlesCanvas from './ParticlesCanvas/ParticlesCanvas';
import { IS_SAGES_USER } from '../misc/constants';
import { IS_SST_USER } from '../misc/constants';

import './App.css';
import './AppAlt.css';

class App extends React.Component {
  /**
   * Tries an SSO login before rendering app so screen stays white
   */
  constructor(props) {
    super(props);
    this.state = { triedSSO: false };
  }

  componentDidMount() {
    this.checkExpired()
    this.trySSO();
  }

  checkExpired() {
    /**
     * If LOGIN flow, checks recency parameter, in case the user saved
     * SST Accounts link as the bookmark. This would trigger a double login
     * because the client would not have the code verifier required for
     * the auth code exchange saved in local storage anymore.
      */
    if (window.location.pathname.endsWith(FLOWS.LOGIN)) {
      const searchParams = Object.fromEntries(new URLSearchParams(window.location.search));
      if (!searchParams.expires || searchParams.expires < (new Date()).getTime()) {
        window.location = searchParams.redirect_uri;
      }
    }
  }

  trySSO() {
    // if (window.location.pathname.endsWith(FLOWS.LOGIN) && window.location.search.includes('sso=true')) {
    // if (window.location.pathname.endsWith(FLOWS.LOGIN)) {
    if (
        window.location.pathname.endsWith(FLOWS.LOGIN) && (
            window.location.search.includes('illuminator') ||
            window.location.search.includes('blackbox')
        )
    ) {
      const clientParams = {
        ...Object.fromEntries(new URLSearchParams(window.location.search)),
        ...{ grant_type: 'sso' },
      };
      HTTP.authorize({}, clientParams).then(
        (response) => {
          if (response.statusCode === 200) {
            window.location = response.json.redirect;
          } else {
            this.setState({ triedSSO: true });
          }
        },
      );
    } else {
      this.setState({ triedSSO: true });
    }
  }

  render() {
    if (this.state.triedSSO) {
      return (
        <div className="app">
          <MainModal />
        </div>
      );
    }
    return <div className="app" />;
  }
}

export default App;
