import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import base32Encode from '../../misc/base32';
import { FLOWS, IS_SAGES_USER, IS_SST_USER, VARIANT } from "../../misc/constants";
import IssueModal from '../IssueModal/IssueModal';
import RootFlow from '../RootView/RootView';

import './MainModal.css';
import './MainModalAlt.css';
import HTTP from "../../misc/HTTP";
import ParticlesCanvas from "../ParticlesCanvas/ParticlesCanvas";
import WavesCanvas from "../WavesCanvas/WavesCanvas";
import { BackgroundImage } from "../BackgroundImage/BackgroundImage";

class MainModal extends React.Component {
  /**
   * setting newMFASecret here, so it doesn't change on re-renders of
   * component where it's used.
   */
  constructor(props) {
    super(props);
    this.newMFASecret = base32Encode(window.crypto.getRandomValues(new Uint8Array(15)));
    this.state = { triedAutoLookup: false, savedEmail: null, isIssueActive: false };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.closeIssueModalOnEsc);
    this.tryAutoLookup()
  }

  tryAutoLookup() {
    let savedEmail = localStorage.getItem('email');
    if (savedEmail) {
      HTTP.emailLookup(savedEmail, FLOWS.LOGIN).then(
        (response) => {
          if (response.statusCode === 200) {
            this.setState({ triedAutoLookup: true, isIssueActive: false, savedEmail });
          } else {  // user either doesn't exist, hasn't registered, or needs a reset
            this.setState({ triedAutoLookup: true, isIssueActive: false, savedEmail: null });
          }
        },
      );
    } else {
      this.setState({ triedAutoLookup: true, isIssueActive: false, savedEmail: null });
    }
  }

  setIsIssueActive(isIssueActive) {
    this.setState({...this.state, isIssueActive});
  }

  closeIssueModalOnEsc(event) {
    if ((event.charCode || event.keyCode) === 27) {
      this.setIsIssueActive(false);
    }
  };

  render() {
    const newMFASecret = this.newMFASecret;
    if (!this.state.triedAutoLookup) {
      return <div className="modal-container"/>;
    }
    return (
      <div>
        {/* OLD BACKGROUND EFFECTS in case we want to revert */}
        {/* <ParticlesCanvas/> */}
        {/* <WavesCanvas/> */}
        <div className="modal-container">
          <div className="bg-image-container">
            <BackgroundImage/>
          </div>
          {/* When the Issue Modal is showing, hide the main modal with opacity */}
          <div className={'main-modal' + VARIANT} style={this.state.isIssueActive ? {opacity: 0} : {opacity: 1}}>
            <div className={'logo-container' + VARIANT}>
              <img className="logo" src={IS_SAGES_USER || IS_SST_USER ? "/logo-white.svg" : "/logo-dark.svg"} alt="SST Logo"/>
            </div>
            <div className={"divider-container" + VARIANT}>
              <div className={"divider-line" + VARIANT}/>
            </div>
            <div className={'body-container' + VARIANT}>
              <BrowserRouter>
                <Routes>
                  <Route
                    path="/login"
                    element={
                      <RootFlow
                        savedEmail={this.state.savedEmail}
                        initialFlow={FLOWS.LOGIN}
                        newMFASecret={newMFASecret}
                      />
                    }
                  />
                  <Route
                    path="/update"
                    element={<RootFlow initialFlow={FLOWS.UPDATE} newMFASecret={newMFASecret}/>}
                  />
                  <Route
                    path="/register"
                    element={<RootFlow initialFlow={FLOWS.REGISTER} newMFASecret={newMFASecret}/>}
                  />
                  <Route
                    path="/reset"
                    element={<RootFlow initialFlow={FLOWS.RESET} newMFASecret={newMFASecret}/>}
                  />
                  <Route path="/"/>
                </Routes>
              </BrowserRouter>
              <div className="issues-wrapper">
                <p className={"having-trouble" + VARIANT}>Having trouble?</p>
                <p className={"issues" + VARIANT} onClick={() => this.setIsIssueActive(true)}>Report issues</p>
              </div>
              <div style={{height: '40px'}}/>
            </div>
          </div>
          <IssueModal isActive={this.state.isIssueActive} handleExit={() => this.setIsIssueActive(false)}/>
        </div>
      </div>
    );
  }
}


export default MainModal;
