const ALPHABET= 'ABCDEFGHIJKLMNOPQRSTUVWXYZ234567';

const createDataView = (data) => {
    if (data instanceof Int8Array || data instanceof Uint8Array || data instanceof Uint8ClampedArray) {
        return new DataView(data.buffer, data.byteOffset, data.byteLength)
    }
    if (data instanceof ArrayBuffer) {
        return new DataView(data)
    }
    throw new TypeError('Expected `data` to be an ArrayBuffer, Buffer, Int8Array, Uint8Array or Uint8ClampedArray')
};

const base32Encode = (data) => {
    const padding = true;  // Enable padding for RFC 4648/3548 Base32 encoding.
    const view = createDataView(data);  // Create  DataView over an ArrayBuffer/8-bit typed array.

    let bits = 0;
    let value = 0;
    let output = '';

    for (let i = 0; i < view.byteLength; i++) {
        value = (value << 8) | view.getUint8(i)
        bits += 8

        while (bits >= 5) {
            output += ALPHABET[(value >>> (bits - 5)) & 31]
            bits -= 5
        }
    }

    if (bits > 0) {
        output += ALPHABET[(value << (5 - bits)) & 31]
    }

    if (padding) {
        while ((output.length % 8) !== 0) {
            output += '='
        }
    }

    return output;
};

export default base32Encode;