import { IS_SAGES_USER } from "../../misc/constants"
import { IS_SST_USER } from "../../misc/constants"
import React from "react"

let bg_src = '/light.png'
if (IS_SST_USER) {
    bg_src = '/dark.png'
} else if (IS_SAGES_USER) {
    bg_src = '/sages.png'
}

export const BackgroundImage = () => {
    return (
        <img className="bg-image" src={bg_src}></img>
    )
}