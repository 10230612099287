export const VIEWS = {
  EMAIL: 'EMAIL',
  PASSWORD: 'PASSWORD',
  SEND_SMS: 'SEND_SMS',
  MFA: 'MFA',
  TEMP_PASSWORD: 'TEMP_PASSWORD',
  PICK_UPDATE: 'PICK_UPDATE',
  UPDATE_PASSWORD: 'SET_PASSWORD',
  PICK_MFA: 'PICK_MFA',
  UPDATE_MFA_APP: 'UPDATE_MFA_APP',
  UPDATE_MFA_PHONE: 'UPDATE_MFA_PHONE',
  SUCCESS: 'SUCCESS',
  UPDATE_PHONE: 'UPDATE_PHONE',
};

export const RESPONSES = {
  MFA_REQUIRED: 'MFA required',
  EXPIRED_TOKEN: 'Expired token',
  COMPLETED_REGISTRATION: 'Completed registration',
  COMPLETED_RESET: 'Completed reset',
  INCOMPLETE_REGISTRATION: 'Incomplete account registration',
  INCOMPLETE_RESET: 'Please complete your account reset before proceeding',
};

export const FLOWS = {
  LOGIN: 'login',
  UPDATE: 'update',
  REGISTER: 'register',
  RESET: 'reset',
};

export const MFA_METHODS = {
  APP: 'app',
  SMS: 'sms',
};

export const UPDATE_PHASES = {
  SECRET: 'secret',
  MFA: 'mfa',
};

export const EMAIL_REGEX = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
export const IS_SST_USER = /staging|illuminator|workflow/.test(window.location.href);
export const IS_SAGES_USER = /cvschallenge/.test(window.location.href);

let _TS_PARTICLES_COLOR = {
  bg: "#ffffff",
  fg: "#0f9ac5",
}

export let VARIANT = ""

if(IS_SAGES_USER) {
  _TS_PARTICLES_COLOR = {
    bg: "#000",
    fg: "#f1a04d"
  }
  VARIANT = '-sages'
} else if (IS_SST_USER) {
  _TS_PARTICLES_COLOR = {
    bg: "#1a1a1a",
    fg: "#0f9ac5",
  }
  VARIANT = '-dark'
}

export const TS_PARTICLES_COLOR = _TS_PARTICLES_COLOR