import React, { useState } from 'react';

import './PasswordInput.css';
import './PasswordInputAlt.css';
import { VARIANT } from '../../misc/constants';

const HiddenEyeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M12 5.9748C15.79 5.9748 19.17 8.1048 20.82 11.4748C20.23 12.6948 19.4 13.7448 18.41 14.5948L19.82 16.0048C21.21 14.7748 22.31 13.2348 23 11.4748C21.27 7.08481 17 3.9748 12 3.9748C10.73 3.9748 9.51 4.1748 8.36 4.5448L10.01 6.1948C10.66 6.0648 11.32 5.9748 12 5.9748ZM10.93 7.1148L13 9.1848C13.57 9.4348 14.03 9.8948 14.28 10.4648L16.35 12.5348C16.43 12.1948 16.49 11.8348 16.49 11.4648C16.5 8.9848 14.48 6.9748 12 6.9748C11.63 6.9748 11.28 7.0248 10.93 7.1148ZM2.01 3.8448L4.69 6.52481C3.06 7.8048 1.77 9.5048 1 11.4748C2.73 15.8648 7 18.9748 12 18.9748C13.52 18.9748 14.98 18.6848 16.32 18.1548L19.74 21.5748L21.15 20.1648L3.42 2.4248L2.01 3.8448ZM9.51 11.3448L12.12 13.9548C12.08 13.9648 12.04 13.9748 12 13.9748C10.62 13.9748 9.5 12.8548 9.5 11.4748C9.5 11.4248 9.51 11.3948 9.51 11.3448ZM6.11 7.9448L7.86 9.69481C7.63 10.2448 7.5 10.8448 7.5 11.4748C7.5 13.9548 9.52 15.9748 12 15.9748C12.63 15.9748 13.23 15.8448 13.77 15.6148L14.75 16.5948C13.87 16.8348 12.95 16.9748 12 16.9748C8.21 16.9748 4.83 14.8448 3.18 11.4748C3.88 10.0448 4.9 8.8648 6.11 7.9448Z" fill="#9AA4B2"/>
    </svg>
  )
}

const EyeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M12 6.5C15.79 6.5 19.17 8.63 20.82 12C19.17 15.37 15.79 17.5 12 17.5C8.21 17.5 4.83 15.37 3.18 12C4.83 8.63 8.21 6.5 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z" fill="#9AA4B2"/>
    </svg>
  )
}

function PasswordInput({value, className, onChange, isTemp = false, isPassword1 = null, statusMsg = {msg: '', isError: false}}) {
  const [isHidden, setIsHidden] = useState(true);

  let placeholderText = "Password"
  if (isTemp) {
    placeholderText = "Enter Temporary Password"
  } else if (isPassword1) {
    placeholderText = "New Password"
  } else if (isPassword1 !== null) {
    placeholderText = "Confirm New Password"
  }

  return (
    <div className={"password-container" + VARIANT + (statusMsg.isError ? " inputError" : '')}>
      <input
        className={`password` + VARIANT + `${className || ''}`}
        name='password'
        type={isHidden ? 'password' : 'text'}
        placeholder={placeholderText}
        value={value}
        onChange={onChange}
        autoFocus={className !== ' password2'}
        data-testid={"password" + (isPassword1 ? "1" : "2")}
        onClick={onChange}
      />
      <div className={"eye-wrapper" + VARIANT} onClick={() => setIsHidden(!isHidden)}>
        {!isHidden ? <EyeIcon /> : <HiddenEyeIcon />}
      </div>
    </div>
  )
}

export default PasswordInput;
