import React from "react";
import { useEffect } from "react";

const getTOTP = (email, secret) => {
  return `otpauth://totp/SST%20Accounts:${email}?secret=${secret}&issuer=SST%20Accounts&algorithm=SHA1&digits=6&period=30`;
}

export function useOutsideClick(refs, handler = undefined) {
  // TODO : instantiate ref here so as to not have to do it before using this
  useEffect(() => {
      function handleClickOutside(event) {
          if (!handler) return
           //   Prevent QR Code Modal overlay from closing
          if(event.target === document.getElementsByClassName("scan")[0]) return
          // Clicked browser's scrollbar
          if (
              event.target === document.getElementsByTagName("html")[0] &&
              event.clientX >= document.documentElement.offsetWidth
          )
              return

          let containedToAnyRefs = false
          for (const rf of refs) {
              if (rf && rf.current && rf.current.contains(event.target)) {
                  containedToAnyRefs = true
                  break
              }
          }

          // Not contained to any given refs
          if (!containedToAnyRefs) {
              handler()
          }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside)
      }
  }, [refs, handler])
}

export { getTOTP }