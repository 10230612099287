const HTTP = {
  emailLookup: async (email, phase) => {
    let url = new URL(`${process.env.REACT_APP_API_ENDPOINT}/oauth/v1/lookup`);
    url.searchParams.append('username', email);
    url.searchParams.append('phase', phase);
    try {
      const response = await fetch(url.toString(), { mode: 'cors' });
      const json = await response.json();
      return { statusCode: response.status, json };
    } catch (e) {
      return { statusCode: 500, json: { detail: 'Something went wrong; please try again later' } };
    }
  },
  messageLookup: async (clientId) => {
    let url = new URL(`${process.env.REACT_APP_API_ENDPOINT}/oauth/v1/message/${clientId}`);
    try {
      const response = await fetch(url.toString(), { mode: 'cors' });
      const json = await response.json();
      return { statusCode: response.status, json };
    } catch (e) {
      return { statusCode: 500, json: { detail: 'Something went wrong; please try again later' } };
    }
  },
  authorize: async (userParams, clientParams) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/oauth/v1/authorize`;
    const params = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({ ...userParams, ...clientParams }),
    };
    try {
      const response = await fetch(url, params);
      const json = await response.json();
      return { statusCode: response.status, json };
    } catch (e) {
      return { statusCode: 500, json: { detail: 'Something went wrong; please try again later' } };
    }
  },

  reset: async (resetParams) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/oauth/v1/reset`,
      {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(resetParams),
      },
    );
    const json = await response.json();
    return { statusCode: response.status, json };
  },

  sendSMS: async (token, phoneNumber, mfaSecret) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/oauth/v1/sms`,
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${token}`,
        },
        body: new URLSearchParams({ phone: phoneNumber, mfa_secret: mfaSecret }),
      },
    );
    const json = await response.json();
    return { statusCode: response.status, json };
  },

  updateToken: async (updateParams) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/oauth/v1/update_token`,
      {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(updateParams),
      },
    );
    const json = await response.json();
    return { statusCode: response.status, json };
  },

  update: async (token, userParams) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/oauth/v1/update`,
      {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${token}`,
        },
        body: new URLSearchParams(userParams),
      },
    );
    const json = await response.json();
    return { statusCode: response.status, json };
  },

  report: async (name, email, message) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/oauth/v1/report`,
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name, email, message }),
      },
    )
    const json = await response.json();
    return { statusCode: response.status, json };
  },

  getCountry: () => {
    return fetch(`${process.env.REACT_APP_API_ENDPOINT}/oauth/v1/country`)
      .then((response) => response.json())
      .then((data) => data);
  },

  getSubscriptions: async (email) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/oauth/v1/subscriptions?email=${encodeURIComponent(email)}`,
      {
        method: 'GET',
        mode: 'cors'
      },
    );
    const json = await response.json();
    return { statusCode: response.status, json };
  },

  getAzureLink: async(username, state, redirectURI, clientId, codeChallenge) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/oauth/v1/azure/redirect`,
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, state, redirectURI, clientId, codeChallenge })
      }
    )
    const json = await response.json();
    return { json }
  }
};

export default HTTP;
