import React from 'react';
import './AudienceMsg.css';

function AudienceMsg(props) {
  if (props.audienceMsg && props.show) {
    const { text, subtext } = props.audienceMsg
    return (
      <div className='audienceMsgWrapper'>
        <img src="/icons/error.png" />
        <div>
          <div className="audienceMsgText">
            {text}
          </div>
          <div className="audienceMsgSubtext">
            {subtext}
          </div>
        </div>
        <div className="audienceMsgClose" onClick={() => props.setShow(false)}>
          &times;
        </div>
      </div>
    );
  }
  return null;
}

export default AudienceMsg;
