import React, { useRef, useState } from "react";
import { isValidPhoneNumber } from 'react-phone-number-input';

import StatusMsg from "../../StatusMsg/StatusMsg";

import './UpdatePhoneView.css';
import './UpdatePhoneViewAlt.css';
import 'react-phone-number-input/style.css';
import { VARIANT } from "../../../misc/constants";
import COUNTRIES from "./countries.json";

const ExpandIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M13.825 6.9126L10 10.7293L6.175 6.9126L5 8.0876L10 13.0876L15 8.0876L13.825 6.9126Z" fill="#697586"/>
    </svg>
  )
}

const UpdatePhoneView = (props) => {
  const [sentCode, setSentCode] = useState(false);
  const [resentCode, setResentCode] = useState(false);
  const [country, setCountry] = useState("US")
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [countrySearch, setSearch] = useState("")
  const countrySearchRef = useRef(null)
  const [nationalNumber, setNational] = useState("")
  const setPhone = props.setPhone

  const onSelectorClick = () => {
    setDropdownOpen(true)
    countrySearchRef.current.focus()
    setSearch("")
  }

  const inputOnBlur = () => {
    setTimeout(() => {
      setDropdownOpen(false)
      setSearch("")
    }, 100)
  }

  const countryOnSelect = (country) => {
    setCountry(country)
  }

  const currentlySelectedCountry = COUNTRIES.find((option) => option.code === country)
  const filteredCountries = COUNTRIES.filter((option) => option.name.toLowerCase().includes(countrySearch.toLowerCase()))

  const getParsedPhone = () => {
    return `${currentlySelectedCountry.dial_code} ${nationalNumber}`;
  }

  const sendSMS = (event) => {
    event.preventDefault();  // prevents page reload
    props.sendSMS(getParsedPhone());
    setPhone(getParsedPhone())
    setSentCode(true);
    if(sentCode) setResentCode(true)
  }

  return (
    <div>
      <div>
        <p className={"view-header" + VARIANT}>Update Phone Number</p>
        {/* TODO: I don't like how I had to shrink and center this */}
        <p className={"view-title" + VARIANT} style={{maxWidth: '400px', paddingLeft: '55px'}}>
          Enter your phone number and click &quot;Send SMS&quot; to get an MFA code.
        </p>
        <StatusMsg statusMsg={props.statusMsg} resentCode={resentCode}/>
        {!props?.statusMsg?.msg && <div style={{height: '24px'}}/>}
        <form className="phone-form" onSubmit={sendSMS}>
          <div style={{position: 'relative', display: 'flex', gap: '12px', width: '430px', alignItems: 'center'}}>
            <div
              className={"country-code-picker" + VARIANT}
              onClick={onSelectorClick}
              data-testid="country-code-picker"
            >
              <img height={15} width={20} src={`https://flagcdn.com/${currentlySelectedCountry.code.toLowerCase()}.svg`}/>
              {currentlySelectedCountry.dial_code}
              <span style={{marginLeft: "auto"}}><ExpandIcon/></span>
            </div>
            {dropdownOpen &&
              <div className="country-dropdown">
                {filteredCountries.map((country) => (
                  <div
                    className="country-option"
                    key={country.name}
                    onClick={() => countryOnSelect(country.code)}
                    data-testid={"country-option-" + country.code}
                  >
                    <img height={15} width={20} src={`https://flagcdn.com/${country.code.toLowerCase()}.svg`}/>
                    <span style={{maxWidth: '300px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>
                      {country.name}
                    </span>
                    {` (${country.dial_code})`}
                  </div>
                ))}
              </div>
            }
            <input
              style={{position: 'absolute', height: 0, width: 0, padding: 0, margin: 0, border: 'none'}}
              ref={countrySearchRef}
              value={countrySearch}
              onChange={(e) => setSearch(e.target.value)}
              onBlur={inputOnBlur}
            />
            <input
              className={"phone-input" + VARIANT}
              value={nationalNumber}
              onChange={(e) => setNational(e.target.value)}
              data-testid="phone-number-field"
              placeholder="Enter phone number"
            >
            </input>
          </div>
          
          <div style={{height: '24px'}}/>
          <button
            data-testid="updatePhone-sms-button"
            className={"proceed" + VARIANT}
            disabled={!nationalNumber || !isValidPhoneNumber(currentlySelectedCountry.dial_code + nationalNumber)}
          >
            Send SMS
          </button>
          <button data-testid="updatePhone-back-button" className={"cancel" + VARIANT} onClick={props.onBack}>Back</button>
        </form>
      </div>
    </div>
  )
}

export default UpdatePhoneView;
