import React, {useRef} from "react";

import './EmailView.css';
import './EmailViewAlt.css';
import ErrorMsg from "../../ErrorMsg/ErrorMsg";
import { VARIANT } from "../../../misc/constants";

function EmailView(props) {
  const emailRef = useRef(null);
  // Added email state just to validate email for button
  const [email, setEmail] = React.useState("")

  const statusMsg = props.statusMsg
  const error = statusMsg.isError

  const onSubmit = (event) => {
    event.preventDefault();  // prevents page reload
    props.onSubmit(emailRef.current.value);
  }

  const changeHandler = (event) => {
    setEmail(event.target.value)
    props.resetStatusMsg()
  }

  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  return (
    <form onSubmit={onSubmit}>
      <h1
        className={"view-header" + VARIANT}
        style={{padding: '40px 40px 12px 40px'}}
      >
        Sign In
      </h1>
      <p className={"view-title" + VARIANT}>Please enter your registered email address to sign in.</p>
      {/* <StatusMsg statusMsg={props.statusMsg}/> */}
      <input
        name='username'
        className={'email' + (error ? ' inputError' : '') + ' ' + VARIANT}
        value={email}
        ref={emailRef}
        placeholder="Email address"
        spellCheck={false}
        onChange={changeHandler}
        onClick={() => props.resetStatusMsg()}
        data-testid={"email-input-field"}
      />
      <ErrorMsg statusMsg={props.statusMsg}/>
      <button
        className={"proceed" + VARIANT}
        type="submit"
        data-testid={"email-proceed-button"}
        style={{marginTop: '50px', marginBottom: '24px'}}
        disabled={!validateEmail(email)}
      >
        Next
      </button>
    </form>
  )
}

export default EmailView;