import React from "react";
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { MdOutlineMarkEmailRead } from 'react-icons/md'
import { FLOWS } from "../../../misc/constants";

import './SuccessView.css';
import './SuccessViewAlt.css';
import { VARIANT } from "../../../misc/constants";

function SuccessView(props) {

  // TODO: Not a great implementation. Prob better to change how RootView does this
  const msg = props.msg
  let header = 'Success'
  let title = ''
  let Icon = AiOutlineCheckCircle
  if (msg === 'Registration already completed.') {
    header = msg
    title = "You've already completed your registration."
  } else if (msg === 'Account factors are fully set.') {
    header = msg
    title = ""
  } else if (msg === `Sent Password Reset Email.`) {
    header = "Reset Email Sent."
    title = "Please check your email."
    Icon = MdOutlineMarkEmailRead
  } else if (msg === "Account reset completed.") {
    header = "Account Reset."
    title = "Your account has been reset successfully."
  } else if (msg === 'Completed Reset.' || msg === "Completed Registration.") {
    header = msg
    title = "You can now navigate away from this page"
  } else if (msg === 'Successfully updated MFA method.') {
    header = "MFA Method Updated."
    title = "Successfully updated MFA method."
  }

  const doneHandler = () => {
    if (props.flow === FLOWS.RESET || props.flow === FLOWS.REGISTER) {
      // In case the original redirect doesn't work, can try again with the done button.
      props.redirect(750)
      return
    }
    window.location.reload()
  }

  return (
    <div>
      <div style={{height:'48px'}}/>
      <div className="icon-wrapper">
        <Icon/>
      </div>
      <p className={"success-header" + VARIANT}>{header}</p>
      <p className={"success-title" + VARIANT}>{title}</p>
      <div style={{height:'50px'}}/>
      <button data-testid="success-done-button" className={"proceed" + VARIANT} onClick={doneHandler}>Done</button>
    </div>
  )
}

export default SuccessView;
