import React, { useEffect, useState } from 'react';

import {
  FLOWS, MFA_METHODS, RESPONSES, UPDATE_PHASES, VIEWS,
} from '../../misc/constants';
import HTTP from '../../misc/HTTP';
import { getTOTP } from '../../misc/utils';
import EmailView from '../Views/EmailView/EmailView';
import MFAView from '../Views/MFAView/MFAView';
import PasswordView from '../Views/PasswordView/PasswordView';
import PickMFAView from '../Views/PickMFAView/PickMFAView';
import PickUpdateView from '../Views/PickUpdateView/PickUpdateView';
import UpdateMFAAppView from '../Views/UpdateMFAAppView/UpdateMFAAppView';
import UpdateMFAPhoneView from '../Views/UpdateMFAPhoneView/UpdateMFAPhoneView';
import SendSMSView from '../Views/SendSMSView/SendSMSView';
import UpdatePasswordView from '../Views/UpdatePasswordView/UpdatePasswordView';
import SuccessView from '../Views/SuccessView/SuccessView';
import UpdatePhoneView from '../Views/UpdatePhoneView/UpdatePhoneView';
import AudienceMsg from '../AudienceMessage/AudienceMsg'

import './RootView.css';

function RootView({ initialFlow, newMFASecret, savedEmail }) {
  const [flow, setFlow] = useState(initialFlow);
  const [country, setCountry] = useState('US');
  const [forgotPassword, setForgotPassword] = useState(false);
  const [email, setEmail] = useState(savedEmail);
  const [password, setPassword] = useState(null);
  const [smsDetails, setSMSDetails] = useState({ phoneNumber: null, token: null });
  const [updateToken, setUpdateToken] = useState(null);
  const [statusMsg, setStatusMsg] = useState({ msg: '', isError: false });
  const [audienceMsg, setAudienceMsg] = useState(null);
  const [showAudience, setShowAudience] = useState(true)
  const [view, setView] = useState(savedEmail && flow === FLOWS.LOGIN ? VIEWS.PASSWORD : VIEWS.EMAIL);
  const [phone, setPhone] = useState();
  const [mfaSkippable, setMfaSkippable] = useState(false);
  const resetStatusMsg = () => { setStatusMsg({ msg: '', isError: false }); };

  const customHeight = 
    // Email view has custom smaller height
    (view === VIEWS.EMAIL) ||
    // TEMPORARY SOLUTION: password view has custom height bc of audience msg
    (audienceMsg && (view === VIEWS.PASSWORD || view === VIEWS.TEMP_PASSWORD))

  const init = async () => {
    const clientParams = Object.fromEntries(new URLSearchParams(window.location.search));
    const { client_id } = clientParams;
    const { statusCode, json } = await HTTP.messageLookup(client_id)
    if (statusCode === 200) {
      setAudienceMsg(json)
    }
  }

  useEffect(() => {
    init()
  }, [])

  const updateEmailCookie = (givenEmail) => {
    if (givenEmail) {
      localStorage.setItem('email', givenEmail);
    } else {
      localStorage.removeItem('email');
    }
  };

  const parseMFAConfigAndSetView = (response) => {
    if (response.json.phoneNumber) {
      setSMSDetails({ phoneNumber: response.json.phoneNumber, token: response.json.loginToken });
    }
    if (response.json.isMfaSkippable) {
      setMfaSkippable(response.json.isMfaSkippable)
    }
    setView(response.json.phoneNumber ? VIEWS.SEND_SMS : VIEWS.MFA);
  };

  const handleEmailSubmit = (givenEmail, selectedRememberMe) => {
    // check https://surgicalsafety.atlassian.net/wiki/spaces/BBI/pages/2673016872/User+States+Lookup+Calls
    updateEmailCookie(selectedRememberMe ? givenEmail : null);
    HTTP.emailLookup(givenEmail, flow).then(
      (response) => {
        if (![200, 302, 403, 307].includes(response.statusCode)) {
          const msg = response.json.detail;
          setStatusMsg({
            msg: typeof msg === 'string' ? msg : 'Something went wrong.',
            isError: true
          });
        } else {
          localStorage.setItem('email', givenEmail);
          resetStatusMsg();
          setEmail(givenEmail);
          switch (flow) {
            case FLOWS.LOGIN:
            case FLOWS.UPDATE:
              if (response.statusCode === 200) {
                setView(VIEWS.PASSWORD);
              } else if (response.json.detail === RESPONSES.INCOMPLETE_REGISTRATION) {
                setFlow(FLOWS.REGISTER);
              } else if (response.json.detail === RESPONSES.INCOMPLETE_RESET) {
                setFlow(FLOWS.RESET);
              } else if (response.statusCode === 307 && flow === FLOWS.LOGIN) {
                handleAzureRedirect(givenEmail);
              } else if (response.statusCode === 307 && flow === FLOWS.UPDATE) {
                setView(VIEWS.PASSWORD)
              } else {
                setStatusMsg({ msg: response.json.detail, isError: true });
              }
              break;
            case FLOWS.REGISTER:
              if (response.statusCode === 200) {
                setView(VIEWS.TEMP_PASSWORD);
              } else if (response.statusCode === 302) {
                setView(VIEWS.PASSWORD);
              } else {
                setStatusMsg({ msg: 'Registration already completed.', isError: false });
                setView(VIEWS.SUCCESS);
              }
              break;
            case FLOWS.RESET:
              if (response.statusCode === 200) {
                setView(VIEWS.TEMP_PASSWORD);
              } else if (response.statusCode === 302) {
                setView(VIEWS.PASSWORD);
              } else if (response.json.detail === RESPONSES.COMPLETED_RESET) {
                setStatusMsg({ msg: 'Account factors are fully set.', isError: false });
                setView(VIEWS.SUCCESS);
              } else {
                setStatusMsg({ msg: response.json.detail, isError: true });
              }
              break;
            default: return null;
          }
        }
      },
    );
  };

  const handleWrongEmail = () => {
    resetStatusMsg();
    setEmail(null);
    setView(VIEWS.EMAIL);
  };

  const handleForgotPassword = () => {
    resetStatusMsg();
    HTTP.reset({ username: email }).then(
      (response) => {
        if (response.statusCode === 200) {
          setForgotPassword(true);
          parseMFAConfigAndSetView(response);
        } else {
          setStatusMsg({ msg: response.json.detail, isError: true });
        }
      },
    );
  };

  const handlePasswordSubmit = (givenPassword) => {
    setForgotPassword(false);
    if (flow === FLOWS.LOGIN) {
      const clientParams = Object.fromEntries(new URLSearchParams(window.location.search));
      const userParams = { username: email, password: givenPassword };
      HTTP.authorize(userParams, clientParams).then(
        (response) => {
          if (response.statusCode === 200) {
            resetStatusMsg();
            setPassword(givenPassword);
            if (response.json.detail === RESPONSES.MFA_REQUIRED) {
              parseMFAConfigAndSetView(response);
            } else {
              window.location = response.json.redirect;
            }
          } else {
            const msg = response.json.detail;
            setStatusMsg({
              msg: typeof msg === 'string' ? msg : 'Something went wrong.',
              isError: true
            });
          }
        },
      );
    } else {
      const updateParams = { username: email, secret: givenPassword };
      HTTP.updateToken(updateParams).then(
        (response) => {
          if (response.statusCode === 200) {
            setPassword(givenPassword);
            resetStatusMsg();
            switch (flow) {
              case FLOWS.UPDATE:
                parseMFAConfigAndSetView(response);
                break;
              case FLOWS.REGISTER:
                setUpdateToken(response.json.updateToken);
                setView(view === VIEWS.TEMP_PASSWORD ? VIEWS.UPDATE_PASSWORD : VIEWS.PICK_MFA);
                break;
              case FLOWS.RESET:
                if (response.json.detail === RESPONSES.MFA_REQUIRED) { // pwd only reset
                  parseMFAConfigAndSetView(response);
                } else {
                  setUpdateToken(response.json.updateToken);
                  setView(view === VIEWS.TEMP_PASSWORD ? VIEWS.UPDATE_PASSWORD : VIEWS.PICK_MFA);
                }
                break;
            }
          } else {
            setStatusMsg({ msg: response.json.detail, isError: true });
          }
        },
      );
    }
  };

  const handleSendSMS = () => {
    HTTP.sendSMS(smsDetails.token).then(
      (response) => {
        if (response.statusCode === 200) {
          if (view === VIEWS.SEND_SMS) {
            // First send the SMS
            setView(VIEWS.MFA);
            resetStatusMsg();
          } else {
            // If a user resent the SMS
            setStatusMsg({
              msg: `Re-sent MFA code to ${smsDetails.phoneNumber}.`,
              isError: false,
            });
          }
        } else if (response.detail === RESPONSES.EXPIRED_TOKEN) {
          setStatusMsg({ msg: 'Session expired; please re-enter your password.', isError: true });
          setView(VIEWS.PASSWORD);
        } else {
          setStatusMsg({ msg: `Unexpected error: ${response.json.detail}`, isError: true });
        }
      },
    );
  };

  const handleUpdateSendSMS = (phone) => {
    HTTP.sendSMS(updateToken, phone, newMFASecret).then(
      (response) => {
        if (response.statusCode === 200) { // user exists
          setStatusMsg({ msg: 'Sent MFA code', isError: false });
          setView(VIEWS.UPDATE_MFA_PHONE)
        } else {
          switch (response.detail) {
            case RESPONSES.EXPIRED_TOKEN:
              setStatusMsg({ msg: 'Session expired; please re-enter your password.', isError: true });
              setView(VIEWS.PASSWORD);
              break;
            default:
              setStatusMsg({ msg: `Unexpected error: ${response.detail}`, isError: true });
          }
        }
      },
    );
  };

  const handleMFASubmit = (MFACode, rememberMFA = false) => {
    const userParams = {
      username: email,
      mfa_code: MFACode,
      mfa_method: smsDetails.phoneNumber ? MFA_METHODS.SMS : MFA_METHODS.APP,
      remember_mfa: rememberMFA
    };
    if (forgotPassword) {
      HTTP.reset(userParams).then(
        (response) => {
          if (response.statusCode === 200) {
            setStatusMsg({
              msg: `Sent Password Reset Email.`,
              isError: false,
            });
            setView(VIEWS.SUCCESS);
          } else {
            setStatusMsg({ msg: response.json.detail, isError: true });
          }
        },
      );
    } else if (flow === FLOWS.LOGIN) {
      const clientParams = Object.fromEntries(new URLSearchParams(window.location.search));
      HTTP.authorize({ ...userParams, password }, clientParams).then(
        (response) => {
          if (response.statusCode === 200) { // successful login; redirect back to client
            window.location = response.json.redirect;
          } else {
            setStatusMsg({ msg: response.json.detail, isError: true });
          }
        },
      );
    } else { // can be UPDATE flow or RESET flow when user reset password
      HTTP.updateToken({ ...userParams, secret: password }).then(
        (response) => {
          if (response.statusCode === 200) {
            resetStatusMsg();
            setUpdateToken(response.json.updateToken);
            setView(flow === FLOWS.UPDATE ? VIEWS.PICK_UPDATE : VIEWS.UPDATE_PASSWORD);
          } else {
            setStatusMsg({ msg: response.json.detail, isError: true });
          }
        },
      );
    }
  };

  const handlePickUpdate = (isPassword) => {
    resetStatusMsg();
    setView(isPassword ? VIEWS.UPDATE_PASSWORD : VIEWS.PICK_MFA);
  };

  const handleBack = () => {
    resetStatusMsg();
    switch (view) {
      case VIEWS.UPDATE_MFA_APP:
      case VIEWS.UPDATE_PHONE:
        setView(VIEWS.PICK_MFA);
        break;
      case VIEWS.MFA:
        setView(VIEWS.PASSWORD);
        break;
      case VIEWS.SEND_SMS:
        setView(VIEWS.PASSWORD);
        break;
      default:
        setView(VIEWS.PICK_UPDATE);
    }
  };

  const handleUpdatePassword = (newPassword) => {
    const userParams = { phase: UPDATE_PHASES.SECRET, new_secret: newPassword };
    HTTP.update(updateToken, userParams).then(
      async (response) => {
        if (response.statusCode === 201) {
          setPassword(newPassword);
          if (flow === FLOWS.UPDATE) {
            setStatusMsg({ msg: 'Successfully updated password.', isError: false });
            setView(VIEWS.PICK_UPDATE);
          } else {
            HTTP.emailLookup(email, FLOWS.LOGIN).then(
              (response2) => {
                if (response2.statusCode === 200) { // RESET flow for only a password update
                  setStatusMsg({ msg: 'Account reset completed.', isError: false });
                  setView(VIEWS.SUCCESS);
                  // After finishing forgot password process, redirect user
                  handleSubscriptionRedirect(750)
                } else { // either REGISTER flow or RESET flow that didn't hit above if-block
                  // Clear out any potential leftover Status Messages (particularly, if a user entered a too common password when they registered)
                  // Then go to MFA with no lingering status message
                  resetStatusMsg();
                  setView(VIEWS.PICK_MFA);
                }
              },
            );
          }
        } else if (response.json.detail === RESPONSES.EXPIRED_TOKEN) {
          setStatusMsg({ msg: 'Session expired; please re-enter your password.', isError: true });
          setView(VIEWS.PASSWORD);
        } else {
          setStatusMsg({ msg: response.json.detail, isError: true });
        }
      },
    );
  };

  const handlePickMFA = (isSMS) => {
    if (isSMS) { HTTP.getCountry().then((response) => setCountry(response)); }
    setView(isSMS ? VIEWS.UPDATE_PHONE : VIEWS.UPDATE_MFA_APP);
  };

  const handleUpdateMFA = (MFACode, MFAMethod, phone) => {
    const userParams = {
      phone,
      phase: UPDATE_PHASES.MFA,
      mfa_code: MFACode,
      mfa_method: MFAMethod,
      mfa_secret: newMFASecret,
    };
    HTTP.update(updateToken, userParams).then(
      (response) => {
        if (response.statusCode === 201) {
          if (flow === FLOWS.UPDATE) {
            setStatusMsg({ msg: 'Successfully updated MFA method.', isError: false });
            setView(VIEWS.SUCCESS)
          } else { // includes REGISTER and RESET flows
            setStatusMsg({
              msg: `Completed ${flow === FLOWS.RESET ? 'Reset.' : 'Registration.'}`,
              isError: false,
            });
            setView(VIEWS.SUCCESS);
            const urlParams = new URLSearchParams(window.location.search)
            const redirect_uri = urlParams.get('redirect_uri')
            // Added this to be able to redirect users to specific products using search params
            if (redirect_uri) {
              setTimeout(() => {
                window.location = redirect_uri
              }, 1000)
            } else {
              // THIS IS WHERE WE WILL CALL /subscriptions
              handleSubscriptionRedirect(2000)
            }
            
          }
        } else {
          setStatusMsg({ msg: response.json.detail, isError: true });
        }
      },
    );
  };

  const handleAzureRedirect = (email) => {
    const clientParams = Object.fromEntries(new URLSearchParams(window.location.search))
    const {state, redirect_uri, client_id, code_challenge} = clientParams
    HTTP.getAzureLink(email, state, redirect_uri, client_id, code_challenge).then(
      (response) => {
        if (response.json) {
          window.location.replace(response.json)
        } else {
          setStatusMsg({msg: 'There was a problem connecting you to Azure', isError: true})
          setView(VIEWS.PASSWORD)
        }
      }
    )
  }

  const handleSubscriptionRedirect = (delay = 2000) => {
    HTTP.getSubscriptions(email).then(
      (response) => {
        if (response.statusCode === 200) {
          setTimeout(() => {
            if (response.json.length > 0) {
              window.location = response.json[0]
            }
          }, delay)
        }
    })
  }

  const renderView = () => {
    switch (view) {
      case VIEWS.EMAIL: return <EmailView statusMsg={statusMsg} onSubmit={handleEmailSubmit} resetStatusMsg={resetStatusMsg}/>;
      case VIEWS.SUCCESS: return <SuccessView msg={statusMsg.msg} flow={flow} redirect={handleSubscriptionRedirect}/>;
      case VIEWS.PICK_MFA: return <PickMFAView onSubmit={handlePickMFA} onBack={handleBack} />;
      case VIEWS.PICK_UPDATE:
        return <PickUpdateView statusMsg={statusMsg} onSubmit={handlePickUpdate} />;
      case VIEWS.PASSWORD:
        return (
          <PasswordView
            statusMsg={statusMsg}
            audienceMsg={audienceMsg}
            email={email}
            onWrongEmail={handleWrongEmail}
            onForgot={handleForgotPassword}
            onSubmit={handlePasswordSubmit}
            resetStatusMsg={resetStatusMsg}
          />
        );
      case VIEWS.SEND_SMS:
        return (
          <SendSMSView
            statusMsg={statusMsg}
            phoneNumber={smsDetails.phoneNumber}
            sendSMS={handleSendSMS}
            handleBack={forgotPassword ? handleBack : null}
          />
        );
      case VIEWS.MFA:
        return (
          <MFAView
            statusMsg={statusMsg}
            audienceMsg={audienceMsg}
            phoneNumber={smsDetails.phoneNumber}
            handleBack={handleBack}
            resendSMS={handleSendSMS}
            onSubmit={handleMFASubmit}
            resetStatusMsg={resetStatusMsg}
            forgotPassword={forgotPassword}
            flow={flow}
            mfaSkippable={mfaSkippable}
          />
        );
      case VIEWS.TEMP_PASSWORD: return (
        <PasswordView
          statusMsg={statusMsg}
          audienceMsg={audienceMsg}
          email={email}
          isTemp
          onWrongEmail={handleWrongEmail}
          onSubmit={handlePasswordSubmit}
          resetStatusMsg={resetStatusMsg}
        />
      );
      case VIEWS.UPDATE_PASSWORD:
        return (
          <UpdatePasswordView
            statusMsg={statusMsg}
            onSubmit={handleUpdatePassword}
            onBack={handleBack}
            resetStatusMsg={resetStatusMsg}
          />
        );
      case VIEWS.UPDATE_MFA_APP:
        return (
          <UpdateMFAAppView
            statusMsg={statusMsg}
            totp={getTOTP(email, newMFASecret)}
            onBack={handleBack}
            onSubmit={handleUpdateMFA}
            resetStatusMsg={resetStatusMsg}
          />
        );
      case VIEWS.UPDATE_MFA_PHONE:
        return (
          <UpdateMFAPhoneView
            statusMsg={statusMsg}
            secret={newMFASecret}
            onBack={handleBack}
            sendSMS={handleUpdateSendSMS}
            onSubmit={handleUpdateMFA}
            phone={phone}
            resetStatusMsg={resetStatusMsg}
          />
        );
      case VIEWS.UPDATE_PHONE:
        return (
          <UpdatePhoneView
            statusMsg={statusMsg}
            secret={newMFASecret}
            country={country}
            onBack={handleBack}
            sendSMS={handleUpdateSendSMS}
            onSubmit={handleUpdateMFA}
            phone={phone}
            setPhone={setPhone}
          />
        )
    }
  };
  return (
    <>
      <div className={"root-view" + (customHeight ? 'on-email-view' : '')} key={view}>
        {renderView()}
      </div>
      <AudienceMsg audienceMsg={audienceMsg} show={showAudience} setShow={setShowAudience}/>
    </>
  );
}

export default RootView;
