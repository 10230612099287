import React from 'react';
import { MdErrorOutline, MdCheckCircleOutline } from 'react-icons/md';

import './StatusMsg.css';

function StatusMsg(props) {
  if (props.statusMsg.msg) {
    return (
      <>
        <div className={`status-msg ${props.statusMsg.isError ? "error" : "success"}`}>
          {props.statusMsg.isError ? <MdErrorOutline /> : <MdCheckCircleOutline />}
          &nbsp;{props.statusMsg.msg}
        </div>
        {(props.statusMsg.msg.startsWith('Re-sent') || props.resentCode) &&
        <span style={{color: 'white'}}>
            <div className="tooltip"> &#9432;
                <span className="tooltiptext"> There is currently an issue sending SMS messages through some Canadian cellular carriers. We are working with our SMS provider to resolve this issue as soon as possible. If you require login urgently, please reach out to your facility administrator for an account reset. This will allow you to switch MFA method from SMS to an authenticator application.
                </span>
            </div>
            Still not receiving SMS?
        </span>
        }
      </>
    );
  }
  return null;
}

export default StatusMsg;
