import React, { useState } from "react";
import './MFADigits.css';
import './MFADigitsAlt.css';
import { VARIANT } from "../../misc/constants";

const NAME_TO_DIGIT = {'zero': 0, 'one': 1, 'two': 2, 'three': 3, 'four': 4, 'five': 5}
const ACTIONS = {ADD: 'ADD', DELETE: 'DELETE', PASTE: 'PASTE'}

const digitReducer = (oldDigits, action) => {
  let newDigits = [...oldDigits];
  if (action.type === ACTIONS.ADD) {
    newDigits[action.targetIdx] = action.digit;
  } else if (action.type === ACTIONS.DELETE) {
    newDigits[action.targetIdx] = '';
  } else if (action.type === ACTIONS.PASTE) {
    newDigits = action.newDigits;
  }
  return newDigits;
}

const MFADigits = ({ digits, digitDispatch, digitRefs, isEnabled, error=false, resetStatusMsg}) => {

  const onInput = (event) => {
    const targetIdx = NAME_TO_DIGIT[event.target.name];
    const input = event.nativeEvent.data;
    if (input && /^[0-9]$/.test(input)) {
      digitDispatch({type: ACTIONS.ADD, targetIdx: targetIdx, digit: input});
      if (targetIdx < 5) {
        digitRefs.current[targetIdx + 1].current.focus();
      }
    }
    resetStatusMsg()
  }

  const onKeyDown = (event) => {
    const targetIdx = NAME_TO_DIGIT[event.target.name];
    if (event.key === 'Backspace') {
      digitDispatch({type: ACTIONS.DELETE, targetIdx: targetIdx});
      digitRefs.current[Math.max(0, targetIdx - 1)].current.focus();
    } else if (event.key === 'Delete') {
      digitDispatch({type: ACTIONS.DELETE, targetIdx: targetIdx});
    } else if (event.key === 'ArrowLeft') {
      digitRefs.current[Math.max(0, targetIdx - 1)].current.focus();
    } else if (event.key === 'ArrowRight') {
      digitRefs.current[Math.min(5, targetIdx + 1)].current.focus();
    }
    resetStatusMsg()
  }

  const onPaste = (event) => {
    let pastedData = event.clipboardData.getData('Text');
    if (/^[\d]{6}$/.test(pastedData)) {  // 6 digits
      digitDispatch({type: ACTIONS.PASTE, newDigits: pastedData.split('')});
      digitRefs.current[5].current.focus();
    }
    resetStatusMsg()
  }

  return (
    <div className="mfa-input-container">
      {digits.map((digit, idx) => {
        return <input
          className={"mfa-input" + VARIANT + (error ? " mfa-error" : "")}
          disabled={isEnabled !== undefined ? !isEnabled : false}
          key={idx}
          value={digit}
          ref={digitRefs.current[idx]}
          name={Object.keys(NAME_TO_DIGIT)[idx]}
          autoFocus={idx === 0}
          tabIndex={idx + 1}
          onPaste={onPaste}  // TODO complete this
          onChange={(event) => onInput(event)}
          onKeyDown={(event => onKeyDown(event))}
          required autoComplete="off"
          placeholder=""
          type={'text'}
          inputMode="numeric"
          pattern="[0-9]*"
          min="0"
          max="9"
          data-testid={`mfa-input-${idx}`}
        />
      })}
    </div>
  )
}
export default MFADigits;
export {digitReducer};
