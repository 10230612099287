import React from 'react';

import './ErrorMsg.css';

function ErrorMsg(props) {
  if (props.statusMsg.msg && !props.statusMsg.msg.startsWith('Re-sent')) {
    return (
      <>
        <div className={`error-msg`} style={props.onMFA ? {marginLeft: '7.5%'} : {}}>
          {props.statusMsg.msg}
        </div>
      </>
    );
  }
  return null;
}

export default ErrorMsg;