import React, { useRef } from "react"
import "./QRModal.css"
import "./QRModalAlt.css"
import QRCode from "qrcode.react"
import { useOutsideClick } from "../../misc/utils"
import { VARIANT } from "../../misc/constants"

const CloseIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <mask id="mask0_1060_29422" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
                <rect width="32" height="32" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_1060_29422)">
                <path d="M8.53317 25.3322L6.6665 23.4655L14.1332 15.9989L6.6665 8.53219L8.53317 6.66553L15.9998 14.1322L23.4665 6.66553L25.3332 8.53219L17.8665 15.9989L25.3332 23.4655L23.4665 25.3322L15.9998 17.8655L8.53317 25.3322Z" fill="#121926"/>
            </g>
        </svg>
    )
}

function QRModal({ show, setShow, totp }) {
    const modalRef = useRef(null)

    const handleClose = () => {
        setShow(false)
    }

    useOutsideClick([modalRef], handleClose)

    return (
        <div className={`qr-modal-bg ${show ? "visible" : "hidden"}`}>
            <div
                ref={modalRef}
                className={`qr-modal-content${VARIANT} ${show ? "visible" : "hidden"}`}
            >
                <div
                    data-testid={"qr-close-modal"}
                    className={"qr-modal-close" + VARIANT}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </div>
                <div style={{ height: "100px" }}></div>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div className={"qr-code-wrapper" + VARIANT}>
                        <QRCode value={totp} size={200} />
                    </div>
                </div>
                <p className={"qr-text" + VARIANT}>
                    Google Authenticator and Microsoft Authenticator are free to
                    download via the Apple App Store or the Google Play Store.
                    Enable biometric authentication for added security.
                </p>
                <img className="app-store" src="/App Store.png"></img>
            </div>
        </div>
    )
}

export default QRModal
