import React, { useRef, useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdErrorOutline } from "react-icons/md"

import HTTP from "../../misc/HTTP";
import './IssueModal.css';
import './IssueModalAlt.css';

import { VARIANT } from "../../misc/constants";

function IssueModal({ isActive, handleExit }) {
  const [isSuccess, setIsSuccess] = useState(null);
  const [email, setEmail] = useState('')
  const [emailBlurred, setEmailBlurred] = useState(false); 
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const messageRef = useRef(null);

  function isValidEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return email.length < 1 || regex.test(email);
  }

  const onSubmit = (event) => {
    event.preventDefault();
    HTTP.report(nameRef.current.value, emailRef.current.value, messageRef.current.value).then(
      (response) => {setIsSuccess(response.statusCode === 200)}
    );
  }

  React.useEffect(() => {
    setIsSuccess(null)
  }, [isActive])

  const clickExitHandler = (e) => {
    e.preventDefault()
    handleExit()
  }

  const onEmailChange = (e) => {
    setEmail(e.target.value)
    setEmailBlurred(false)
  }

  const isEmailValid = isValidEmail(email)
  const disableSubmit = email.length === 0 || !isEmailValid
  const emailErrorState = emailBlurred && disableSubmit

  const renderForm = () => {
    return (
        <div>
          <p className={"view-header" + VARIANT}>Report an Issue</p>
          <div className={"issue-modal-title" + VARIANT}>
            <input
              ref={nameRef}
              type="text"
              placeholder="Name (optional)"
              maxLength="50"
              style={{margin: '0px 0px 40px 0px'}}
            />
          </div>
          <div className={"issue-modal-title" + VARIANT}>
            <input
              ref={emailRef}
              type="email"
              placeholder="Email (required)"
              maxLength="75"
              onChange={onEmailChange}
              onBlur={() => setEmailBlurred(true)}
              className={emailErrorState ? 'inputError' : ''}
              style={{margin: '0px 0px 40px 0px'}}
            />
          </div>
          {emailErrorState && <p className="issue-error">Please enter a valid email.</p>}
          <div className={"issue-modal-title" + VARIANT}>
            <textarea
              required
              ref={messageRef}
              className={"issue-modal-message" + VARIANT}
              placeholder="Describe the issue you are experiencing."
              maxLength="700"
            />
          </div>
        </div>
    )
  }

  const renderSubmittedView = () => {
    return (
      <div>
        <div className="icon-wrapper">
          {isSuccess ? <AiOutlineCheckCircle/> : <MdErrorOutline style={{color: "darkred"}}/>}
        </div>
        <p
          className={"view-header" + VARIANT}
          style={{padding: '0px 40px 20px 40px'}}
        >
          {isSuccess ? "Issue submitted" : "Something went wrong"}
        </p>
        <p
          className={"view-title" + VARIANT}
          style={{marginBottom: '70px'}}
        >
          {isSuccess ? "Issue submitted successfully." : "Please try again later."}
        </p>
      </div>
    )
  }

  return (
    <div className={"issue-modal-bg" + (isActive ? " visible" : " hidden")}>
        <div className="issue-main-modal">
            <div className={'logo-container' + VARIANT}>
                <img className="logo" src={VARIANT === "" ? "/logo-dark.svg" : "/logo-white.svg"} alt="SST Blue Logo"/>
            </div>
            <div className={"divider-container" + VARIANT}>
              <div className={"divider-line" + VARIANT}/>
            </div>
            <div className={"issue-modal-content" + VARIANT + (isActive ? " visible" : " hidden")}>
                <form onSubmit={isSuccess === null ? onSubmit : handleExit} noValidate>
                    {isSuccess === null ? renderForm() : renderSubmittedView()}
                    <button
                        className={"proceed" + VARIANT}
                        type="submit"
                        disabled={disableSubmit}
                    >
                        {isSuccess === null ? "Submit" : "Close"}
                    </button>
                    {isSuccess === null && <button className={"cancel" + VARIANT} onClick={clickExitHandler}>Back</button>}
                </form>
            </div>
        </div>
    </div>
  );
}

export default IssueModal;
