import React, { createRef, useReducer, useRef, useState } from "react";

import MFADigits, {digitReducer} from "../../MFADigits/MFADigits";

import './UpdateMFAPhoneView.css';
import './UpdateMFAPhoneViewAlt.css';
import 'react-phone-number-input/style.css';
import ErrorMsg from "../../ErrorMsg/ErrorMsg";

import { AiOutlineClose, AiOutlineCheckCircle } from "react-icons/ai";
import { MdOutlineSmartphone } from "react-icons/md";

import { VARIANT } from "../../../misc/constants";

const parsePhoneNumber = (phoneNumber) => {
  const phoneSplit = phoneNumber.split(" ")
  return {
    countryCallingCode: phoneSplit[0],
    nationalNumber: phoneSplit[1]
  }
}

const UpdateMFAPhoneView = (props) => {
  const [sentCode, setSentCode] = useState(true);
  const [resentCode, setResentCode] = useState(false);
  const digitRefs = useRef([]);
  const [digits, digitDispatch] = useReducer(digitReducer, Array(6).fill(''));
  digitRefs.current = digits.map((_, idx) => digitRefs.current[idx] ?? createRef());
  const phone = props.phone

  const getParsedPhone = () => {
    const parsedPhone = parsePhoneNumber(phone);
    return `${parsedPhone.countryCallingCode} ${parsedPhone.nationalNumber}`;
  }

  const getCensoredPhone = () => {
    const parsedPhone = parsePhoneNumber(phone);
    let censoredNumber = ''
    for (let i = 0; i < parsedPhone.nationalNumber.length; i++) {
      if (i < parsedPhone.nationalNumber.length - 4) {
        censoredNumber = censoredNumber + "*"
      } else {
        censoredNumber = censoredNumber + parsedPhone.nationalNumber[i]
      }
    }
    return parsedPhone.countryCallingCode + censoredNumber
  }

  const onSubmit = (event) => {
    event.preventDefault();  // prevents page reload
    const digits = digitRefs.current.map(digitRef => digitRef.current.value).join('');
    props.onSubmit(digits, 'sms', getParsedPhone());
  }

  const sendSMS = (event) => {
    event.preventDefault();  // prevents page reload
    props.sendSMS(getParsedPhone());
    setSentCode(true);
    if(sentCode) {
      setResentCode(true)
      digitDispatch({type: "PASTE", newDigits: Array(6).fill('')})
    }
  }

  const renderPhonePill = () => {
    if (phone) {
      return (
        <div className='phone-pill-wrapper'>
          <div className={'phone-pill-container' + VARIANT}>
            <MdOutlineSmartphone />&nbsp;
            <div className="phone-pill-phone">{getCensoredPhone()}&nbsp;</div>
            <div className={"cross-wrapper" + VARIANT} onClick={props.onBack}><AiOutlineClose /></div>
          </div>
        </div>
      )
    }
  }

  const renderResentMsg = () => {
    if (resentCode) {
      return (
        <div className={"resent-msg-alt" + VARIANT} >
          <AiOutlineCheckCircle style={{color: '#12B76A'}}/> MFA code was successfully re-sent.
        </div>
      )
    }
  }

  const viewTitle = `Enter temporary 6-digit code sent to ${getCensoredPhone()}.`

  return (
    <div>
      <div>
        <p className={"view-header" + VARIANT}>Enter MFA Code</p>
        {/* TODO: I don't like how I had to shrink and center this */}
        <p
          className={"view-title" + VARIANT}
          style={{maxWidth: '400px', paddingLeft: '55px', marginBottom: '0px', whiteSpace: 'nowrap', overflow: 'hidden'}}
        >
          {viewTitle}
        </p>
        {renderResentMsg()}
        {renderPhonePill()}
        <div style={{height: '12px'}}/>
        <form onSubmit={onSubmit}>
          <MFADigits
            digits={digits}
            digitDispatch={digitDispatch}
            digitRefs={digitRefs}
            isEnabled={sentCode}
            error={props.statusMsg.isError}
            resetStatusMsg={props.resetStatusMsg}
          />
          {props.statusMsg.isError && <ErrorMsg statusMsg={props.statusMsg} onMFA={true}/>}
          <div style={{height: '12px'}}/>
          <div className="vertical-splitter">
            <button
              className={"proceed" + VARIANT} type="submit"
              disabled={!(digits.every((entry) => entry.length))}
              data-testid="phoneMFA-submit-button"
            >Submit
            </button>
            <button
              data-testid="phoneMFA-resent-button"
              type='button'
              className={"cancel" + VARIANT}
              onClick={sendSMS}
            >
              Re-send SMS
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default UpdateMFAPhoneView;
