import React, {useRef, useReducer, createRef, useState} from "react";
import MFADigits, {digitReducer} from "../../MFADigits/MFADigits"

import './MFAView.css';
import './MFAViewAlt.css';
import ErrorMsg from "../../ErrorMsg/ErrorMsg";
import { VARIANT, FLOWS } from "../../../misc/constants";

import { AiOutlineCheckCircle } from "react-icons/ai";

function MFAView({
  statusMsg,
  phoneNumber,
  resendSMS,
  onSubmit,
  resetStatusMsg,
  forgotPassword,
  flow,
  mfaSkippable
}) {
  const digitRefs = useRef([]);
  const [digits, digitDispatch] = useReducer(digitReducer, Array(6).fill(''));
  digitRefs.current = digits.map((_, idx) => digitRefs.current[idx] ?? createRef());
  const [resent, setResent] = useState(false)
  const [trust, setTrust] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault();
    const digitValues = digitRefs.current.map(digitRef => digitRef.current.value).join('');
    onSubmit(digitValues, trust);
  }

  const handleResendSMS = (event) => {
    event.preventDefault();
    digitDispatch({type: "PASTE", newDigits: Array(6).fill('')})
    setResent(true)
    resendSMS()
  }

  const handleClickTrust = () => {
    setTrust(!trust)
    digitRefs.current[0].current.focus()
  }

  const renderTitle = () => {
    const suffix = `${forgotPassword ? ' to get Password Reset Email.' : '.'}`
    return <>
    <p className={"view-header" + VARIANT}>
      {
        `Enter MFA Code`
      }
    </p>
    <p className={"view-title" + VARIANT}>
      {phoneNumber ?
      `Enter temporary 6-digit code sent to ${phoneNumber}${suffix}`
      :
      `Enter MFA code from Authenticator App${suffix}`
      }
    </p>
    </>
  }

  const renderButtons = () => {
    const disabled = !(digits.every((entry) => entry.length))
    const submitBtn = <button data-testid={"mfa-proceed-button"} className={"proceed" + VARIANT} type="submit" disabled={disabled}>Submit</button>
    return <div className="vertical-splitter">
      {!phoneNumber && <div style={{height: '40px'}}></div>}
      {submitBtn}
      {phoneNumber && renderResendSms()}
    </div>
  }
  
  const renderResentMsg = () => {
    if (resent) {
      return (
        <div className={"resent-msg" + VARIANT}>
          <AiOutlineCheckCircle style={{color: '#12B76A'}}/> MFA code was successfully re-sent.
        </div>
      )
    }
  }

  const renderResendSms = () => {
    return <button data-testid={"mfa-resend-button"} className={"cancel" + VARIANT} onClick={handleResendSMS}>{`Re-send SMS`}</button>
  }

  return (
    <div>
      {renderTitle()}
      {renderResentMsg()}
      <form onSubmit={handleSubmit}>
        {!phoneNumber && <div style={{height: '24px'}}></div>}
        <MFADigits
          digits={digits}
          digitDispatch={digitDispatch}
          digitRefs={digitRefs}
          error={statusMsg.isError}
          resetStatusMsg={resetStatusMsg}
        />
        <ErrorMsg statusMsg={statusMsg} onMFA={true}/>
        {!phoneNumber && statusMsg.msg &&
          <div style={{height: '1px'}} />
        }
        {<div style={{height: !forgotPassword && flow === FLOWS.LOGIN && mfaSkippable ? '20px' : '48px'}}/>}
        {!forgotPassword && flow === FLOWS.LOGIN && mfaSkippable &&
          <div
            className={'trust-container' + VARIANT}
            style={{
              marginTop: !phoneNumber ? '24px' : undefined,
              marginBottom: !phoneNumber ? '-24px' : undefined
            }}
          >
            <input
              type="checkbox"
              checked={trust}
              onChange={handleClickTrust}
              className={'trust-checkbox' + VARIANT}
            />
            Trust this device
            <div
              className="tooltip"
              style={{fontSize: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '-8px'}}
            >
              <InfoTooltipIcon color={VARIANT === "" ? '#697586' : 'white'} />
              <span className="tooltiptext" style={{maxWidth: '240px', fontSize: '12px', right: '-120px', top: '-60px'}}>
                Trusting this device will reduce the frequency of multi-factor authentication on future logins.
              </span>
            </div>
          </div>
        }
        {renderButtons()}
      </form>
    </div>
  )
}

const InfoTooltipIcon = ({color}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M7.3335 4.66634H8.66683V5.99967H7.3335V4.66634ZM7.3335 7.33301H8.66683V11.333H7.3335V7.33301ZM8.00016 1.33301C4.32016 1.33301 1.3335 4.31967 1.3335 7.99967C1.3335 11.6797 4.32016 14.6663 8.00016 14.6663C11.6802 14.6663 14.6668 11.6797 14.6668 7.99967C14.6668 4.31967 11.6802 1.33301 8.00016 1.33301ZM8.00016 13.333C5.06016 13.333 2.66683 10.9397 2.66683 7.99967C2.66683 5.05967 5.06016 2.66634 8.00016 2.66634C10.9402 2.66634 13.3335 5.05967 13.3335 7.99967C13.3335 10.9397 10.9402 13.333 8.00016 13.333Z" fill={color}/>
    </svg>
  )
}

export default MFAView;
